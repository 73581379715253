.brand_logo a {
  display: contents; }
  .brand_logo a img {
    width: 100%; }

.brand_logo:first-child {
  margin-left: -10px; }

.brand_powered {
  margin-top: 1em; }
  .brand_powered a {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 0.9em;
    color: #151b18; }

.mt-special {
  margin-top: 0.7rem !important; }
