.podcast-detail {
  padding-top: 90px; }
  .podcast-detail hr {
    border-width: 2px;
    border-color: #265e93; }
  .podcast-detail ul {
    list-style: none; }
    .podcast-detail ul li img {
      max-width: 30px;
      max-height: 30px; }
    .podcast-detail ul li.text {
      padding: 0 0.6rem;
      font-weight: 600; }
    .podcast-detail ul li.text button {
      padding: 0;
      font-weight: 600; }
    .podcast-detail ul.option-ul li:last-child {
      border-left: 2px solid; }
  .podcast-detail .content-image .caption {
    background-color: #e1ece7;
    font-size: 18px; }
    .podcast-detail .content-image .caption p {
      margin-bottom: 0; }
  .podcast-detail .excerpt-podcast p {
    margin-bottom: 30px; }
  .podcast-detail .transcripcion {
    color: #265e93;
    cursor: pointer; }
  .podcast-detail .share-link {
    cursor: pointer; }
  .podcast-detail .trascription-podcast .card {
    background: transparent;
    border: 0;
    border-top: 1px solid #265e93;
    border-radius: 0; }
    .podcast-detail .trascription-podcast .card .card-header {
      background: #e0f0fb;
      border-color: #265e93; }
      .podcast-detail .trascription-podcast .card .card-header button {
        padding: 0;
        font-size: inherit; }
      .podcast-detail .trascription-podcast .card .card-header .btn-link.collapsed img {
        transform: rotate(180deg); }
    .podcast-detail .trascription-podcast .card .card-body {
      padding-left: 0;
      padding-right: 0; }
    .podcast-detail .trascription-podcast .card .card-body span {
      font-family: Open Sans,sans-serif !important;
      line-height: 1.5 !important;
      font-size: 18px !important; }
    .podcast-detail .trascription-podcast .card .card-body p {
      font-family: Open Sans,sans-serif !important;
      line-height: 1.5 !important;
      font-size: 18px !important; }
  .podcast-detail .trascription-podcast .go-up {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    background: #e0f0fb;
    padding: 9px 20px 12px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 9; }
  @media (max-width: 425px) {
    .podcast-detail .trascription-podcast .go-up {
      bottom: 8px;
      right: 8px; } }
  .podcast-detail .trascription-podcast .go-up {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    background: #e0f0fb;
    padding: 9px 20px 12px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2); }
  .podcast-detail .gallery-modal {
    background: rgba(38, 94, 147, 0.92);
    align-items: center; }
    .podcast-detail .gallery-modal.fade.show {
      display: flex !important; }
    .podcast-detail .gallery-modal .modal-dialog {
      margin: 0 auto; }
    .podcast-detail .gallery-modal .modal-body p {
      font-size: 20px; }
    .podcast-detail .gallery-modal .modal-body .images-div {
      border-radius: 10px; }
  .podcast-detail .mr-15 {
    margin-right: 15px; }
