.episodes h1 {
  margin-top: 180px; }

.episodes button .letter-2 {
  margin-top: 1px;
  padding: 0 13px; }

.episodes button .image {
  background: #265e93;
  padding: 4px 3px; }
  .episodes button .image img {
    transform: rotate(90deg);
    margin-bottom: 1px; }

.episodes .placeholder-section {
  margin-top: 200px; }

.episodes .load-more {
  display: flex;
  justify-content: center;
  margin-bottom: 17px; }

@media (min-width: 768px) {
  .episodes .blog-image {
    height: auto; } }
