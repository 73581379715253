.related-content .related-title {
  font-weight: 700;
  color: #265e93; }

.related-content .content-type {
  top: 0;
  padding: 0.6rem;
  background: #44eba6; }

.related-content .book-type {
  bottom: 0; }
  .related-content .book-type img {
    width: 49px; }
