.post-excerpt .post-title {
  font-family: 'Alegreya Sans', sans-serif;
  font-weight: 700;
  color: inherit; }

.post-excerpt .play-image {
  max-width: 84px; }

.post-excerpt .btn {
  border-width: 2px; }
  .post-excerpt .btn .text {
    margin-top: 1px;
    padding: 0 13px; }
  .post-excerpt .btn .image {
    background: #265e93;
    padding: 4px 3px;
    height: 100%;
    display: flex;
    align-items: center; }
    .post-excerpt .btn .image img {
      max-width: 22px;
      max-height: 22px; }

.post-excerpt a {
  border-width: 2px; }
  .post-excerpt a .text {
    margin-top: 1px;
    padding: 0 13px; }
  .post-excerpt a .image {
    background: #265e93;
    padding: 4px 3px; }

.featured-blog {
  padding-top: 200px; }
  .featured-blog .object {
    position: relative; }
    .featured-blog .object::before, .featured-blog .object::after {
      content: '';
      width: 90%;
      height: 65px;
      background: #44eba6;
      display: block;
      float: right; }
    .featured-blog .object::after {
      position: absolute;
      right: 0; }
  @media (max-width: 767px) {
    .featured-blog .object::before, .featured-blog .object::after {
      position: relative; } }
