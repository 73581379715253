.related-post .post-excerpt hr {
  border-color: #265e93;
  border-width: 2px; }

.related-post .plus-part p {
  font-size: 17px; }

.related-post .text-left {
  color: inherit; }

.related-post .blog-image {
  margin: 0 auto;
  display: block;
  width: 100%;
  height: auto; }

@media (min-width: 768px) {
  .related-post .post-excerpt {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between; }
    .related-post .post-excerpt .description-text {
      min-height: 162px;
      margin-bottom: auto; }
      .related-post .post-excerpt .description-text p {
        margin-bottom: 0; } }
